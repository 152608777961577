.list {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    min-height: 100px;    
}

.item {
    width: 25%;   
    padding: 5px;

    box-sizing: border-box;
}
