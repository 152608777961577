.container {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 42px;
    background: #1b5e20;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.title {
    position: absolute;
    left: 42px;
    top: 3px;
    font-size: 24px;
    color: #ffffff;
}
