.container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 162px;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.container:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px  6px rgba(0, 0, 0, .23);
}

.selected {
    background: #1b5e20;
    color: white;
}

.title {
    font-size: 18px;
    margin-bottom: 10px;
}

.comment {
    font-size: 10px;
    opacity: 0.5;
}
